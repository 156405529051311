import { MaximumScheduleTagLength, SchoolCalendarScheduleViewModel } from '@insights/viewmodels';
import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';

export interface EditableScheduleTitleProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarScheduleViewModel;
}

export const EditableScheduleTitle = observer((props: EditableScheduleTitleProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, viewModel, style } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Column sx={sx} className={className} style={style}>
      <TextField
        label={strings.title}
        fullWidth
        value={viewModel.title}
        onChange={(event) => (viewModel.title = event.target.value)}
        slotProps={{ input: { inputProps: { maxLength: MaximumScheduleTagLength } } }}
      />
    </Column>
  );
});
